import type { ThemeDefinition } from 'vuetify'

export const theme: ThemeDefinition = {
  colors: {
    primary: '#427594',
    alert: '#E02A2A',
    grey1: '#222222',
    grey2: '#494949',

    white: '#fff'
  },
  variables: {}
}
