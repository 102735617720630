<template>
  <v-form
    class="d-flex justify-center w-100 h-100"
    v-model="isFormValid"
    ref="formRef"
    @submit.prevent="handleFormSubmit"
  >
    <template v-if="loading">
      <v-sheet class="d-flex justify-center align-center h-100">
        <v-progress-circular indeterminate color="primary" />
      </v-sheet>
    </template>
    <v-sheet v-else class="w-100" max-width="1140">
      <FormLayout>
        <template #form-header>
          <FormHeader
            title="Vet Information"
          />
        </template>
        <template #form>
          <VetForm :formDetails="vetDetails!" :actionLoading @updateDetails="handleUpdate" />
        </template>
        <template #upload>
          <UploadDoc :formDetails="docDetails" @updateDoc="handleUpdate" />
        </template>
        <template #deactivate-account>
          <DeactivateAccount v-if="!vetDetails?.isSuspended" :id="vetDetails?.id!" type="vet" />
        </template>
      </FormLayout>
    </v-sheet>
    <FormFooter buttonText="Update" :loading="actionLoading" />
  </v-form>
</template>

<script lang="ts" setup>
import VetForm from '@/components/app/veternians/VetForm.vue'
import FormLayout from '@/components/layout/FormLayout.vue'
import DeactivateAccount from '@/components/partials/formUi/DeactivateAccount.vue'
import FormFooter from '@/components/partials/formUi/FormFooter.vue'
import UploadDoc from '@/components/partials/formUi/UploadDoc.vue'
import type { Vets } from '@/stores/types/veternians'
import { useVeterniansStore } from '@/stores/veternians'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { toast } from 'vue3-toastify'

const formRef = ref()
const isFormValid = ref(false)

const loading = ref(false)
const actionLoading = ref(false)
const vetDetails = ref<Vets | null>(null)
const accountDetails = ref()

const veterniansStore = useVeterniansStore()
const route = useRoute()
const id = computed(() => route.params.vetId)

const docDetails = computed(() => ({
  profileImg: vetDetails.value?.profileImg || null,
  documents: vetDetails.value?.documents || []
}))

const handleUpdate = (details: any) => {
  accountDetails.value = { ...accountDetails.value, ...details }
}

const getVet = async () => {
  try {
    loading.value = true
    const data = await veterniansStore.fetchVetDetails(`${id.value}`)
    vetDetails.value = data
  } finally {
    loading.value = false
  }
}

const updateVet = async () => {
  try {
    actionLoading.value = true
    await veterniansStore.updateVet(`${id.value}`, accountDetails.value)
  } finally {
    actionLoading.value = false
  }
}

const handleFormSubmit = async () => {
  if (!isFormValid.value) return
  await updateVet()
  toast.success('Vet updated successfully')
}

onMounted(() => {
  getVet()
})
</script>
