import { defineStore } from 'pinia'
import api from '@/util/api'
import type { State, UpdateUserPayloadDto } from './types/auth'

export const useAuthStore = defineStore('AuthStore', {
  state: (): State => ({
    user: null,
    initializationComplete: false
  }),
  actions: {
    async login(payload: { email: string; password: string }) {
      const data = await api.post('/admin/auth/login', payload)
    },
    async logout() {
      await api.post('/admin/auth/logout')
      this.user = null
    },
    async fetchUser() {
      const { data } = await api.get('/admin/me')
      this.user = data
      return data
    }
  }
})
